import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Paragraph, Row} from "../components/atomics"
import colors from "../colors"
import { useLanguage } from "../hooks"

const text = {
  title: {
    en: 'Privacy Policy',
    pt: 'Política de Privacidade'
  },
  s1: {
    en: 'Information Collection and Use',
    pt: 'Coleta e uso de informações'
  },
  's1.p1': {
    en: 'When you register with Flynow we will ask you for information such as your name and email address. Your information is used only internally and will not be shared with others.',
    pt: 'No ato do seu registro no Flynow nós lhe pedimos informações como seu nome e endereço de e-mail. Sua informação é utilizada apenas internamente e não será compartilhada com outros.',
  },
  's1.p2': {
    en: 'Flynow collects e-mail addresses from those who communicate with us by e-mail and through information sent in voluntary activities, such as participation in surveys. Flynow also collects application usage data without user identification. This usage data we collect is used to improve Flynow and the quality of the service provided',
    pt: 'O Flynow coleta endereço de e-mail de quem se comunica conosco por e-mail e através de informações enviadas em atividades voluntárias, como por exemplo, participação em pesquisas. O Flynow também coleta dados de uso do aplicativo sem identificação do usuário. Esses dados de uso que coletamos é utilizado para melhorias do Flynow e qualidade do serviço prestado'
  },
  s2: {
    en: 'Your data',
    pt: 'Seus dados'
  },
  's2.p1': {
    en: 'Flynow uses business partners that provide hardware, software, networking, storage and related technology needed to run Flynow. Despite the code, database and all rights of the Flynow application; you have full rights to your own information. We will never share personal information with third parties without your prior authorization, and we will never sell your information to third parties.',
    pt: 'Flynow utiliza parceiros comerciais que fornecem hardware, software, networking, armazenamento e tecnologia relacionada necessária para execução do Flynow. Apesar do código, banco de dados e todos os direitos do aplicativo Flynow; você tem total direito sobre sua própria informação. Nunca compartilharemos informações pessoais com terceiros sem sua prévia autorização e jamais venderemos suas informações para terceiros.'
  },
  s3: {
    en: 'Security',
    pt: 'Segurança'
  },
  's3.p1': {
    en: 'All information and data transmitted are secured by the SSL protocol.',
    pt: 'Todas as informações e dados transmitidos são assegurados pelo protocolo SSL.'
  },
  s4: {
    en: 'Changes',
    pt: 'Mudanças'
  },
  's4.p1': {
    en: 'If our information practices change at any time, we will update this page. If you are concerned about how your information is used, you should check this page periodically.',
    pt: 'Se nossas práticas com relação às informações mudarem em algum momento, nós atualizaremos essa página. Se você está preocupado sobre como sua informação é utilizada, você deverá checar essa página periodicamente.'
  },
}

const PrivacyPolicy = () => {
  const {language,setLanguage} = useLanguage();
  return(
  <Layout>
    <SEO title="Política de privacidade" />
    <br/>
    <Row className="column-in-mobile" style={{justifyContent:'space-between'}}> 
      <h1 style={{marginBottom:10}}>{text['title'][language]}</h1>
      <Row style={{alignItems:'center'}}>
        <h5 style={{marginRight:10}}>Language:</h5>
        <button onClick={()=> setLanguage('en')} style={{border:'none', padding:'5px 10px',marginRight:10,background: language === 'en' ? colors.degrade : '#aaa', color: 'white',cursor:'pointer'}}><h5>EN</h5></button>
        <button onClick={()=> setLanguage('pt')} style={{border:'none', padding:'5px 10px',marginRight:10,background: language === 'pt' ? colors.degrade : '#aaa', color: 'white',cursor:'pointer'}}><h5>PT</h5></button>
      </Row>
    </Row>
    <br/>
    <br/>
    <h3>{text['s1'][language]}</h3>
    <Paragraph>
    {text['s1.p1'][language]}
      <br/>
      {text['s1.p2'][language]}    
    </Paragraph>
    <br/>
    
    <h3>{text['s2'][language]}</h3>
    <Paragraph>{text['s2.p1'][language]}</Paragraph>
    
    <br/>
    <h3>{text['s3'][language]}</h3>
    <Paragraph>{text['s3.p1'][language]}</Paragraph>

    <br/>
    <h3>{text['s4'][language]}</h3>
    <Paragraph>{text['s4.p1'][language]}</Paragraph>
  </Layout>
)}

export default PrivacyPolicy
